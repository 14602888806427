import React, { useState } from "react"
const backend = require("../helpers/devsurvival.api.js")

const successMessage = "Thank you for subscribing!"
export default function Subscribe() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [notification, setNotification] = useState("")

  const subscribe = async e => {
    e.preventDefault()
    if (!email || !name) {
      setNotification("Enter both email and name")
      return
    }
    await backend.userSubscribe({ name, email })
    //console.log(subscriber)
    setNotification(successMessage)
  }

  return (
    <div className="subscribe flex-column-start">
      {notification === successMessage ? (
        <h4>{notification}</h4>
      ) : (
        <div>
          <h4>Be the first to get new courses and content</h4>
          <p className="subscription-message"></p>

          <form onSubmit={subscribe} className="flex-row-start">
            <div>
              <input
                className="input"
                type="text"
                placeholder="Name*"
                onChange={e => setName(e.target.value)}
                value={name}
              />
              <input
                className="input"
                type="email"
                placeholder="Email*"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <button className="btn" type="submit">
              Get Notified
            </button>
            {!notification ? <div>{notification}</div> : ""}
          </form>
        </div>
      )}
    </div>
  )
}
