import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Subscribe from "../components/subscribe.js"

export default ({ data, location }) => {
  const { title: siteTitle } = data.site.siteMetadata

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Newsletter" />
      <h4>Newsletter</h4>
      <ol>
        <li>NO spam</li>
        <li>You can unsubscribe anytime</li>
        <li>
          All of the content including courses, guides, source code, and
          downloads are free 😊
        </li>
        <li>Be the first 💯 to receive new courses and content</li>
      </ol>
      <Subscribe />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
